<template>
  <div class="product-container">
    <!-- banner_cp -->
    <div class="banner_cp" style="background-color: #f4f6f8">
      <div class="margin width1480 hidden">
        <div class="l">
          <dl style="display: block">
            <img
              src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/banner_cp.png"
            />
          </dl>
        </div>
        <div class="r">
          <div class="bg a-fdB">
            <dd>探 索 者</dd>
            <dl>全车型可兼容丨匠心耐候材质丨家用快速充电</dl>
            <!-- <ol class="t">
              <a href="">观看产品视频</a>
            </ol> -->
            <ol class="f">
              <dt class="o">
                <span><i style="background-color: #000000"></i></span>
                <p>黑色</p>
              </dt>
            </ol>
            <a @click="$U.openProductFile('tansuozhe')">产品手册</a>
          </div>
        </div>
      </div>
    </div>
    <!-- 充电王者 -->
    <div id="p1">
      <div
        class="cp_cdwz"
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/shouwangpage0002.jpg);
        "
      >
        <div class="margin width1480 hidden" v-if="'Web' == state.appType">
          <div class="con">
            <dd class="cp_bt_y set_color_white">充电王者</dd>
            <ol class="cp_bt_d set_color_white">
              21kW功率 超高充电效率
            </ol>
            <dl class="cp_bt_y set_color_white">
              相比传统充电桩充电时间缩短约36%，高速充电，节约您的时间，自由享受驾驶的乐趣
            </dl>
          </div>
        </div>

        <div class="margin width1480 hidden" v-else>
          <div class="con">
            <dd class="cp_bt_y">充电王者</dd>
            <ol class="cp_bt_d">
              21kW功率 超高充电效率
            </ol>
            <dl class="cp_bt_y">
              相比传统充电桩充电时间缩短约36%，高速充电，节约您的时间，自由享受驾驶的乐趣
            </dl>
          </div>
        </div>
      </div>
    </div>
    <!-- 升级型耐候级外壳材质 -->
    <div id="p2">
      <div
        class="cp_wkcl"
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_wkcl.jpg);
        "
      >
        <div class="margin width1480 hidden">
          <div class="t">
            <dd class="cp_bt_d">升级型耐候级外壳材质</dd>
            <dl class="cp_bt_y">
              <p>
                UL94-V0级TPE壳体，高温阻燃更安全，10级RK等级，轻松面对严寒不开裂
              </p>
              <p>采用PCB三防喷漆，防尘、防雾、防盐</p>
            </dl>
          </div>
          <div class="f">
            <dl>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_wkcl1.png"
                class="l"
              />
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_wkcl2.png"
                class="c"
              />
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_wkcl3.png"
                class="r"
              />
            </dl>
          </div>
        </div>
      </div>
    </div>
    <!-- 多重安全防护 -->
    <div id="p3">
      <div class="margin width1480 hidden">
        <ul class="cp_aqfh">
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh1.png"
                />
              </ol>
              <dd>过压/欠压保护</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh2.png"
                />
              </ol>
              <dd>过流保护</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh3.png"
                />
              </ol>
              <dd>雷电保护</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh4.png"
                />
              </ol>
              <dd>接地保护</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh5.png"
                />
              </ol>
              <dd>防漏电保护</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh6.png"
                />
              </ol>
              <dd>短路保护</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh7.png"
                />
              </ol>
              <dd>环境电源异常数据记录</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images/cp_aqfh8.png"
                />
              </ol>
              <dd>IP55</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh9.png"
                />
              </ol>
              <dd>过温保护</dd>
            </div>
          </li>
          <li>
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh10.png"
                />
              </ol>
              <dd>预约充电</dd>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 智能控制 -->
    <div id="p4">
      <div class="cp_znkz">
        <div class="t">
          <ol>
            智能控制
          </ol>
          <dd>智能芯片安全不伤车</dd>
          <dl>实现电能高效率转换，确保充电稳定不过热</dl>
        </div>
        <div class="f">
          <img
            src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_znkz.jpg"
          />
        </div>
      </div>
    </div>
    <!-- cp_tit -->
    <div id="p5">
      <div class="cp_tit">
        <dd>探索智能充电 让生活更便捷</dd>
        <ol>
          三种充电模式
        </ol>
      </div>
    </div>
    <!-- 便捷充电 -->
    <div id="p6">
      <div
        class="cp_bjcd"
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_bjcd.jpg);
        "
      >
        <div class="margin width1480 hidden">
          <div class="l">
            <!-- <ol>便捷充电</ol> -->
            <dd class="cp_bt_d">即插即用</dd>
            <dl class="cp_bt_y">想充就充，便携充电</dl>
          </div>
        </div>
      </div>
    </div>
    <!-- 防盗电 -->
    <div id="p7">
      <div
        class="cp_fdd"
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_fdd.jpg);
        "
      >
        <div class="margin width1480 hidden">
          <div class="r">
            <!-- <ol>防盗电</ol> -->
            <dd class="cp_bt_d">刷卡充电</dd>
            <dl class="cp_bt_y">配备防盗功能，匹配唯一身份识别智充卡启动</dl>
          </div>
          <img
            src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_fdd_s.png"
            class="tp"
          />
        </div>
      </div>
    </div>
    <!-- APP控制 -->
    <div id="p8">
      <div
        class="cp_appgz"
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_appgz.jpg);
        "
      >
        <div class="margin width1480 hidden">
          <div class="bg">
            <!-- <dd>APP控制</dd> -->
            <dl class="cp_bt_d">定时预约 远程操控</dl>
            <ol class="cp_bt_y">
              无论是在家还是在外，使用APP就能查看汽车的充电状态，轻松远程控制开关！
            </ol>
          </div>
        </div>
        <img
          src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_appgz_r.png"
          class="r"
        />
      </div>
    </div>
    <!-- 智享生活 贴心关怀从心开始 -->
    <div id="p9">
      <div
        class="cp_znsh cp_f_bg_full"
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_znsh.jpg);
        "
      >
        <div class="margin width1480 hidden">
          <div class="t">
            <dd>智享生活 贴心关怀从心开始</dd>
            <dl>
              安装网络覆盖全国360个城市，已为100万户家庭生产并安装了家庭充电桩！
            </dl>
            <ol>
              <a @click="$U.openProductFile('tansuozhe')"
                >产品手册<i class="fa fa-angle-right"></i
              ></a>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive } from "vue";
export default {
  name: "About",
  components: {},
  setup() {
    const { proxy } = getCurrentInstance();

    const state = reactive({
      appType: "Web",
    });

    onMounted(() => {
      document.title = "探索者";

      var u = navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //安卓端
      var isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //IOS端
      const innerWidth = window.innerWidth;
      state.appType = "Web";
      if (isAndroid && innerWidth < 450) {
        state.appType = "Android";
      }
      if (isIos && innerWidth < 450) {
        state.appType = "iOS";
      }

      // 顶部
      window.scrollTo(0, 0);

      $(".banner_cp .width1480 .r ol.f dt").click(function () {
        var liindex = $(".banner_cp .width1480 .r ol.f dt").index(this);
        $(this).addClass("o").siblings().removeClass("o");
        $(".banner_cp .width1480 .l dl")
          .eq(liindex)
          .fadeIn(150)
          .siblings("dl")
          .hide();
      });
      $(window).scroll(function () {
        //为页面添加页面滚动监听事件
        var wst = $(window).scrollTop(); //滚动条距离顶端值
        for (var i = 1; i < 20; i++) {
          if ($("#p" + i) && $("#p" + i).offset()) {
            //加循环
            if ($("#p" + i).offset().top <= wst) {
              //判断滚动条位置
              $("#p" + i).addClass("on"); //给当前导航加c类
              if (i == 9) {
                $("#k10").addClass("on"); //给当前导航加c类
              }
            } else {
              //$("#p"+i).removeClass('on');
            }
          }
        }
      });
    });
    return { state };
  },
};
</script>

<style lang="less" scoped></style>
